import {css, styled, Theme} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {observer} from "mobx-react-lite";
import * as React from 'react';
import {useNavigate} from "react-router";
import {baseStyle} from "./styles";
import {TopBarMenu} from "./TopBarMenu";


const StyledAppBar = styled(AppBar)(({theme}) => `
z-index: ${theme.zIndex.drawer} + 1;
transition: ${theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
})}
`);

const StyledToolbar = styled(Toolbar)(({theme}) => `
    padding-right: ${theme.spacing(2)}; // keep right padding when drawer closed
    text-align: center;
    ${theme.breakpoints.down('sm')} {
        padding-right: ${theme.spacing(0)};
    }
`);
//     [theme.breakpoints.down('xs')]: {
//     paddingRight: theme.spacing(0),
// },

// const StyledMenuButton = styled(IconButton)(`${base}`);

type PropTy = {
    onOpenDrawer: () => unknown,
    onRequestLogin: () => unknown,
    onRequestLogout: () => unknown,
    onOpenAccountManagement: () => unknown,
    onOpenMessageCenter: () => unknown,
    currentLanguage: string,
    loggedIn: boolean,
}


const StyledImage = styled('img')(({theme}: {theme: Theme}) => css`
${typeof baseStyle === 'string' ? baseStyle : baseStyle({theme})} 
`);
const StyledLeftImage = styled('img')(({theme}: {theme: Theme}) => css`
  ${typeof baseStyle === 'string' ? baseStyle : baseStyle({theme})}
  background-image: url('/react-favicon.ico');
  width: 38px;
`);

export const TopBar = observer(function TopBar(props: PropTy) {
    const {
        onOpenDrawer,
        onRequestLogin,
        onRequestLogout,
        onOpenAccountManagement,
        onOpenMessageCenter,
        loggedIn,
    } = props;
    const navigator = useNavigate();
    const [siteIconSrc, setSiteIconSrc] = React.useState('/local/LeftTopBar.svg');


    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function handleDrawerOpen() {
        onOpenDrawer();
    }

    return (
        <StyledAppBar
            position="relative"
        >
            <StyledToolbar disableGutters={true}>

                <StyledImage
                    src={siteIconSrc}
                    alt={process.env.REACT_APP_NAME}
                    width={48}
                    height={48}
                    onClick={() => {
                        navigator('/')
                    }}
                    onError={() => {
                        setSiteIconSrc('/favicon.ico');
                    }}
                />
                <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{
                        flexGrow: 1,
                        fontFamily: "din-2014, sans-serif",
                        fontWeight: 600,
                        fontStyle: "normal",
                        fontSize: "1.5rem",
                    }}
                >
                    {(process.env.REACT_APP_NAME || "")}
                </Typography>
                <TopBarMenu onOpenAccountManagement={onOpenAccountManagement}
                            onOpenMessageCenter={onOpenMessageCenter}
                            onRequestLogin={onRequestLogin}
                            onRequestLogout={onRequestLogout}
                            loggedIn={loggedIn}
                />
                {/*<AccountTopBarIcon onRequestLogin={onRequestLogin}*/}
                {/*                   onRequestLogout={onRequestLogout}*/}
                {/*                   onOpenAccountManagement={onOpenAccountManagement}*/}
                {/*                   onOpenMessageCenter={onOpenMessageCenter}*/}
                {/*                   loggedIn={loggedIn}*/}
                {/*/>*/}
                {/*{!loggedIn && <LanguageTopBarSelector*/}
                {/*    currentLanguage={language}*/}
                {/*/>}*/}
            </StyledToolbar>
        </StyledAppBar>

    )
});

